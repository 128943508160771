#app {
  height: 100%;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.questions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding: 3rem 2rem 2rem;
  text-align: left;
}

.vocabulary {
  display: grid;
  grid-template-columns: repeat(3, 0.8fr); /* Adjust the number of columns as needed */
  justify-items: center;
  gap: 1rem;
  padding: 2rem;
  text-align: left;
  background: #f0f6f0;
}

.questions .card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.questions .card-content > div {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 24px;
}

.questions .card-content > ul {
  margin-left: 50px;
}

.questions .card-content > ul li {
  margin-bottom: 5px;
  font-size: 20px;
}

@media (max-width: 1000px) {
  .questions {
    grid-template-columns: 1fr;
    padding: 3rem 1rem 2rem;
    font-size: 80%;
  }

  .vocabulary {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 700px) {
  .vocabulary {
    grid-template-columns: 1fr;
  }
}
