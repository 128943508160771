:root {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  font-family: 'Inter', sans-serif;

  background: white;
  color: #2e2d2d;

  --background: white;
  --primary: rgb(247, 148, 31);
  --text-primary: #2e2d2d;
  --text-secondary: #606060;
  --text-ternary: #808080;
  --text-error: #cc2c3d;

  --gray-3: #787878;

  --error: #cc2c3d;
  --success: rgb(71, 255, 123);
  --warning: rgb(255, 209, 71);

  --dropdown-bg: #fff;

  --card-background: #fff;

  --btn-primary: linear-gradient(180deg, #f7941f 0%, #f76d1f 100%);

  --header-height: 70px;
}

::-webkit-scrollbar {
  display: none;
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input,
p,
span,
head {
  font-family: 'Inter', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background: var(--background);
  color: #2e2d2d;
  width: 100vw;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

.primary-link {
  color: var(--primary);
}

.mx {
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
}

p {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: var(--text-secondary);
}

h2 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: var(--text-primary);
}

h3 {
  font-weight: 700;
  font-size: 28px;
  line-height: 130%;
  color: var(--text-primary);
}

h5 {
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  color: var(--text-primary);
}

h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: var(--text-primary);
}

.primary {
  color: var(--primary);
}

.text-primary {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--text-primary);
}

.text-secondary {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--text-secondary);
}

.s-16 {
  font-size: 16px;
}

.s-14 {
  font-size: 14px;
}

.s-12 {
  font-size: 12px;
}

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-transform: capitalize;
}

.pad {
  padding-left: 40px;
  padding-right: 40px;
}

.section-title {
  color: var(--text-primary);
  font-size: 20px;
  font-weight: 700;
}

.para {
  font-size: 14px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.session-card {
  border-radius: 20px;
  border: 0.7px solid #ede7df;
  background: var(--white, #fff);
  padding: 25px;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  align-items: center;
}

.align-center {
  align-items: center;
}

.place-center {
  align-items: center;
  justify-content: center;
}

.pointer {
  cursor: pointer;
}

.color-primary {
  color: var(--primary) !important;
}

.text-error {
  color: var(--error);
}

@media (max-width: 768px) {
  body {
    padding-bottom: 50px;
  }
  .pad {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 600px) {
  .pad {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.error-text {
  color: var(--text-error);
  font-size: 14px;
}

.text-primary {
  color: var(--primary);
  font-size: 14px;
  line-height: 1.2;
}

.justify-end {
  justify-content: flex-end;
}

.z-90 {
  z-index: 90 !important;
}

.z-150 {
  z-index: 150 !important;
}

.confetti {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.suspense-loader {
  height: calc(100vh - 70px);
  display: grid;
  place-items: center;
}

.error-msg {
  color: tomato;
}

@media (max-width: 990px) {
  .pb {
    padding-bottom: 50px;
  }
}
